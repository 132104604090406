import "bootstrap-sass"
require('bootstrap-select');
//require('bootstrap-select/js/i18n/defaults-fr_FR');
import "../src/home.scss"

window.jQuery = jQuery;
window.$ = $;

document.addEventListener("turbolinks:load", () => {
  $(document).on('click', '.zoom', function() {
    $('#group-modal').modal('toggle')
    $('.modal-content').html($(this).clone().removeClass('zoom'));
  })

  $('#group-modal').on('click', function() {
    $('#group-modal').modal('toggle')
  })

  $('body').on('mousedown', 'img', function(e){
    e.preventDefault()
  });

  $('body').on('contextmenu', 'img', function(e){
    return false; 
  });
});